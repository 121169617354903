import { createApiRef, IdentityApi } from '@backstage/core-plugin-api';
import { RestClientApi } from '@runway/devkit-common';
import { PlatformUsage } from '../components/Cloudsmith/ServiceAccounts/Request/types';

export type ServiceAccountRequestPayload = {
  serviceAccountName: string;
  platformUsage: PlatformUsage;
  distributionEmail: string;
  shortName: string;
  squads: { id: number; name: string }[];
  requester: { aaId: string; email: string };
};

export type ServiceAccount = {
  name: string;
  slug: string;
  description: string;
  key: string;
  key_expires_at: string;
};

export interface CloudsmithServiceApi {
  requestServiceAccount(serviceAccount: ServiceAccountRequestPayload): Promise<void>;
  refreshServiceAccountToken(serviceAccount: string): Promise<ServiceAccount>;
  getServiceAccount(
    serviceAccountSlug: string,
  ): Promise<ServiceAccount & Omit<ServiceAccountRequestPayload, 'serviceAccountName'>>;
}

export const cloudsmithServiceApiRef = createApiRef<CloudsmithServiceApi>({
  id: 'plugin.infrastructure.cloudsmith',
});

export default class CloudsmithService implements CloudsmithServiceApi {
  constructor(
    private backendUrl: string,
    private identityApi: IdentityApi,
    private restClient: RestClientApi,
  ) {}

  requestServiceAccount = async (serviceAccount: ServiceAccountRequestPayload) => {
    const { token } = await this.identityApi.getCredentials();
    await this.restClient.post<void>(`${this.backendUrl}/api/cloudsmith/service-accounts`, serviceAccount, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  };

  refreshServiceAccountToken = async (serviceAccount: string) => {
    const { token } = await this.identityApi.getCredentials();
    const response = await this.restClient.post<ServiceAccount>(
      `${this.backendUrl}/api/cloudsmith/service-accounts/${serviceAccount}/refresh`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );

    return response.data;
  };

  getServiceAccount = async (serviceAccountSlug: string) => {
    const { token } = await this.identityApi.getCredentials();
    const response = await this.restClient.get<
      ServiceAccount & Omit<ServiceAccountRequestPayload, 'serviceAccountName'>
    >(`${this.backendUrl}/api/cloudsmith/service-accounts/${serviceAccountSlug}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data;
  };
}
